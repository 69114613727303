




	import { Component, Vue } from "vue-property-decorator";

	import EntityCrud from "./entity/EntityCrud.vue";
	import prosecutorModel from "@/api/prosecutor.model";

	@Component({
		components: { EntityCrud },
	})
	export default class Prosecutors extends Vue {
		model: any = prosecutorModel;

		title = "Promotores";
        tableColumns = ["user.name", "user.status", "user.email"];
        initialSort = { key: "user.status", direction: "DESC" };

        formColumns = ["user.name", "user.status", "user.email", "user.password", "phoneNumber", "registry", "cpf", "judicialDistrict"];
	}
